





























































import { Component, Vue } from "vue-property-decorator";
import {
  sendSMS,
  SendSMSReq,
  updatePasswordByEmail,
  updatePasswordByEmailReq,
} from "@/api/user";
import { CommonEmailRes, CommonRes } from "@/api/common";
import { Toast } from "vant";
import { checkPassword } from "@/utils/utils";
import SendButton from "@/components/SendButton.vue";
import { globalData } from '@/utils/config';
@Component({
  components: {
    SendButton,
  },
})
export default class Index extends Vue {
  private req: updatePasswordByEmailReq = {
    email: "",
    password: "",
    code: "",
  };
  private newPassword: string = "";
  private confirmPassword: string = "";
  private email: string = "";
  private isSendSuccess: boolean = false;
  private isShowSMSCode: boolean = false;
  private isClickingk: boolean = false;
  created() {
    console.log("findPassword created");
  }

  private back() {
    this.$router.go(-1);
  }

  /**
   * 执行找回密码
   */
  private async confirmFindPassword() {
    if (!checkPassword(this.newPassword)) {
      Toast(this.$t("passwordError").toString());
      return;
    }
    if (this.newPassword != this.confirmPassword) {
      Toast(this.$t("passwordDiffer").toString());
      return;
    }
    this.req.email = this.req.email.trim();
    if (this.req.email.length == 0) {
      Toast(this.$t("usernameShort").toString());
      return;
    }
    if (this.req.code.length == 0) {
      Toast(this.$t("codeShort").toString());
      return;
    }
    this.req.password = this.newPassword;
    let res: CommonRes = await updatePasswordByEmail(this.req);
    console.log("updatePasswordByEmail", res);
    if (res.code == 0) {
      let savePassword = localStorage.getItem("savePassword");
      if (savePassword != null) {
        localStorage.setItem("password", this.req.password);
      }
      Toast(this.$t("updatePasswordOk").toString());
      this.$router.go(-1);
    }
  }

  private async sendSMS() {
    this.isClickingk = true
    let reqSMS: SendSMSReq = {
      email: this.req.email,
      companyType: "0"
    };
    if (location.host == globalData.yjhUrl) {
      reqSMS.companyType = "1";
    }
    let res: CommonEmailRes = await sendSMS(reqSMS);
    console.log("this.isSendSuccess sendSMS", res)
    console.log(' this.isSendSuccess1111:' + this.isSendSuccess);
    if (res.code == 0) {
      this.email = res.email;
      console.log(' this.isSendSuccess222:' + this.isSendSuccess);
      this.isSendSuccess = !this.isSendSuccess;
      this.isShowSMSCode = true
      Toast(this.$t("sendSMSCodeSuccess").toString());
      console.log(' this.isSendSuccess3333:' + this.isSendSuccess);
      this.isClickingk = false;
    } else {
      this.isClickingk = false;
    }
    console.log(' this.isSendSuccess4444:' + this.isSendSuccess);
  }
}
